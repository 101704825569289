import cloneDeep from 'lodash/cloneDeep';
// const PREFIX = 'utils: reporting: widgetQueries:';
// NOTE: Questionnaire is deprecated
const FORM_COMPONENT_NAMES = ['Form', 'Questionnaire'];
export const findForm = widget => {
  const sections = typeof widget.sections === 'object' ? widget.sections : widget;
  return Object.keys(sections || {}).map(id => (sections || {})[id]).find(_ref => {
    let {
      component
    } = _ref;
    return FORM_COMPONENT_NAMES.includes(component);
  }) || null;
}; // Find the form sections' ID
// within a widget if one exists

export const findFormId = widget => {
  const formSection = findForm(widget);
  return formSection ? findSectionId(widget, formSection) : '';
};
export const findSectionId = (widget, section) => {
  return Object.keys(widget.sections || {}).find(id => (widget.sections || {})[id] === section) || '';
};
export const isFormSection = widgetSection => {
  return FORM_COMPONENT_NAMES.includes(widgetSection.component);
}; // Lookup a Question by ID within a section

export const getFormQuestionById = function (widgetSection) {
  let questionId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (!widgetSection || !isFormSection(widgetSection)) return undefined;
  const {
    questions = []
  } = widgetSection.config || {};
  return questions.find(_ref2 => {
    let {
      id
    } = _ref2;
    return id === questionId;
  });
};
export const isVisualSection = widgetSection => {
  return !isFormSection(widgetSection);
};
export const cloneFormSection = widget => {
  const form = findForm(widget);
  if (!form) return null;
  const result = cloneDeep(form); // Set Identifier on form clone

  const id = findSectionId(widget, form);
  Object.defineProperty(result, 'id', {
    enumerable: false,
    value: id
  });
  return result;
};
export const findVisualSections = widget => {
  return Object.keys(widget.sections || {}).map(id => (widget.sections || {})[id]).filter(_ref3 => {
    let {
      component
    } = _ref3;
    return !FORM_COMPONENT_NAMES.includes(component);
  });
};
export const isReportingWidget = widget => {
  // NOTE: widget.spreadsheet is deprecated
  return Boolean(widget.workbookFileRef || widget.spreadsheet);
}; // Create a default report request
// configuration for forms without
// customized reports

export const getDefaultReportRequests = () => [{
  id: 'default',
  name: 'Default',
  answers: {}
}]; // Lookup the questions
// within a reporting widget

export const getFormQuestions = widget => {
  const form = findForm(widget);

  if (form && form.config && Array.isArray(form.config.questions)) {
    return form.config.questions;
  }

  return [];
}; // Lookup the title of a widget

export const getWidgetTitle = function (widget) {
  let allowPlaceholder = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return widget.title || (widget === null || widget === void 0 ? void 0 : widget.config.heading) || (allowPlaceholder ? 'Unnamed' : '');
};