import { useEffectOnce } from 'react-use';
import { useEffect, useMemo, useSyncExternalStore } from 'react';
import globalConfig from '@/config/general';
import globalEvents from '@/utils/globalEvents';
import { createWrappedError } from '@/utils/errors';
const PREFIX = 'common: Editor: hooks: useEditingAreas:';
const STORE = {
  activeWidgetId: '',
  activeSectionId: '',
  activeSectionEditingArea: '',
  activeEditingAreaMeta: '' // JSON string

};

const useEditingAreas = function () {
  let {
    initialState,
    previewEl
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // Subscribe any global listeners
  useEffectOnce(subscribeGlobalEventListeners); // Subscribe optional event listeners to an editable widget
  // that updates editable areas as they are clicked
  // within the widget while in edit mode

  useEffect(() => {
    if (!previewEl) return () => null;

    const setEditingState = evt => {
      const updates = getEditingAreaFromEvent(evt);
      if (!updates || !updates.sectionId) return; // Delete edit item selection attributes
      // on section switch, when new selection does not contain
      // any section item index or id. Prevents lingering selections

      if (updates.sectionId !== STORE.activeSectionId && !updates.sectionEditingArea) {
        updates.sectionEditingArea = '';
        updates.clickPosition = null;
        updates.editingAreaMeta = '';
      }

      selectEditingArea(updates);
    };

    previewEl.addEventListener('click', setEditingState);
    return () => {
      previewEl.removeEventListener('click', setEditingState);
    };
  }, [previewEl]); // Select an optional initial state

  useEffectOnce(() => {
    if (!initialState) return;
    setTimeout(() => selectEditingArea(initialState));
  }); // Individual state subscribers

  const activeWidgetId = useSyncExternalStore(subscribe, getWidgetIdSnapshot, getWidgetIdSnapshot);
  const activeSectionId = useSyncExternalStore(subscribe, getSectionIdSnapshot, getSectionIdSnapshot);
  const activeSectionEditingArea = useSyncExternalStore(subscribe, getSectionEditingAreaSnapshot, getSectionEditingAreaSnapshot);
  const activeEditingAreaMetaStr = useSyncExternalStore(subscribe, getEditingAreaMeta, getEditingAreaMeta);
  const activeClickPositionX = useSyncExternalStore(subscribe, getSectionClickPositionX, getSectionClickPositionX);
  const activeClickPositionY = useSyncExternalStore(subscribe, getSectionClickPositionY, getSectionClickPositionY);
  const hasClickPosition = typeof activeClickPositionX === 'number' && typeof activeClickPositionY === 'number' && activeClickPositionX >= 0 && activeClickPositionY >= 0;
  const activeClickPosition = useMemo(() => ({
    x: activeClickPositionX,
    y: activeClickPositionY
  }), [activeClickPositionX, activeClickPositionY]); // Parsed result of current editing area meta

  const activeEditingAreaMeta = useMemo(() => {
    if (!activeEditingAreaMetaStr) return null;
    let result;

    try {
      result = JSON.parse(activeEditingAreaMetaStr);
    } catch (err) {
      throw createWrappedError(`${PREFIX} editing area meta not parsable`, err);
    }

    return result;
  }, [activeEditingAreaMetaStr]);
  return {
    activeWidgetId,
    activeSectionId,
    activeSectionEditingArea,
    activeEditingAreaMeta,
    activeClickPosition: hasClickPosition ? activeClickPosition : undefined
  };
}; // Select Editing Area Callback


export const selectEditingArea = payload => {
  // NOTE: ensure select firest after deselect
  setTimeout(() => {
    globalEvents.trigger('editingareaselect', {
      detail: payload
    });
  });
}; // Deselect Editing Areas Callback

export const deselectEditingArea = () => {
  globalEvents.trigger('editingareadeselect');
}; // Notify state subscribers of state changes
// after the editingarea custom events fired

function subscribe(onChange) {
  const unsubscribeSelect = globalEvents.subscribe('editingareaselect', () => setTimeout(onChange));
  const unsubscribeDeselect = globalEvents.subscribe('editingareadeselect', () => setTimeout(onChange));
  return () => {
    unsubscribeSelect();
    unsubscribeDeselect();
  };
} // Individual state snapshot getters


function getWidgetIdSnapshot() {
  return STORE.activeWidgetId;
}

function getSectionIdSnapshot() {
  return STORE.activeSectionId;
}

function getSectionEditingAreaSnapshot() {
  return STORE.activeSectionEditingArea;
}

function getEditingAreaMeta() {
  return STORE.activeEditingAreaMeta;
}

function getSectionClickPositionX() {
  return STORE.activeClickPosition ? STORE.activeClickPosition.x : -1;
}

function getSectionClickPositionY() {
  return STORE.activeClickPosition ? STORE.activeClickPosition.y : -1;
} // Handle the global events of editing
// areas, can only be instantiated once


let areGlobalEventsSubscribed = false;

function subscribeGlobalEventListeners() {
  if (areGlobalEventsSubscribed) return () => null;
  const unsubscribeSelect = globalEvents.subscribe('editingareaselect', evt => {
    const detail = (evt === null || evt === void 0 ? void 0 : evt.detail) || {};
    const {
      sectionId: newActiveSectionId,
      clickPosition,
      editingAreaMeta
    } = detail; // Append optional widget identifer

    if (typeof detail.widgetId !== 'undefined') {
      STORE.activeWidgetId = detail.widgetId;
    } // Required


    STORE.activeSectionId = newActiveSectionId; // Append optional section item identifer

    if (typeof detail.sectionEditingArea !== 'undefined') {
      STORE.activeSectionEditingArea = detail.sectionEditingArea;
    } // Append optional click position


    if (clickPosition) {
      STORE.activeClickPosition = clickPosition;
    } else if (clickPosition === null) {
      delete STORE.activeClickPosition;
    } // Append optional edit area meta


    if (editingAreaMeta) {
      STORE.activeEditingAreaMeta = editingAreaMeta;
    }
  });
  const unsubscribeDeselect = globalEvents.subscribe('editingareadeselect', () => {
    STORE.activeWidgetId = '';
    STORE.activeSectionId = '';
    STORE.activeSectionEditingArea = '';
    delete STORE.activeClickPosition;
  });
  areGlobalEventsSubscribed = true;
  return () => {
    unsubscribeSelect();
    unsubscribeDeselect();
    areGlobalEventsSubscribed = false;
  };
} // Collect all available editing
// areas from a pointer event


const getEditingAreaFromEvent = evt => {
  if (!evt.target) return null;
  const result = {
    sectionId: ''
  };
  const target = evt.target;
  const widgetAttrEl = target.closest(`[${getEditingWidgetAttr()}]`);
  const sectionAttrEl = target.closest(`[${getEditingSectionAttr()}]`);
  const editingAttrEl = target.closest(`[${getEditingAreaAttr()}]`);
  const editingAttrMetaEl = target.closest(`[${getEditingAreaMetaDataAttr()}]`); // Set optional widget ID

  const newActiveWidgetId = (widgetAttrEl === null || widgetAttrEl === void 0 ? void 0 : widgetAttrEl.getAttribute(getEditingWidgetAttr())) || '';
  if (newActiveWidgetId) result.widgetId = newActiveWidgetId; // Set any section ID

  const newActiveSectionId = (sectionAttrEl === null || sectionAttrEl === void 0 ? void 0 : sectionAttrEl.getAttribute(getEditingSectionAttr())) || '';
  if (newActiveSectionId) result.sectionId = newActiveSectionId; // Set optional section edit area

  const newActiveSectionEditArea = (editingAttrEl === null || editingAttrEl === void 0 ? void 0 : editingAttrEl.getAttribute(getEditingAreaAttr())) || '';
  if (newActiveSectionEditArea) result.sectionEditingArea = newActiveSectionEditArea; // Set optional meta data

  const newMetaData = (editingAttrMetaEl === null || editingAttrMetaEl === void 0 ? void 0 : editingAttrMetaEl.getAttribute(getEditingAreaMetaDataAttr())) || '';
  if (newMetaData) result.editingAreaMeta = newMetaData;
  const clientX = evt.clientX;
  const clienty = evt.clientY;
  const hasClickPosition = typeof clientX === 'number' && typeof clienty === 'number' && result.sectionEditingArea;

  if (hasClickPosition) {
    result.clickPosition = {
      x: clientX,
      y: clienty
    };
  }

  return result;
}; // Get an id attribute for a editor form field


export const getEditorAreaControlId = (params, customArea) => {
  const {
    sectionId,
    sectionEditingArea
  } = params;
  if (!customArea && !sectionEditingArea) return '';
  return `editor-section-${sectionId}-${customArea || sectionEditingArea}`;
}; // Create an editing widget attribute

export const getEditingWidgetAttr = () => `data-${globalConfig.productNameSpace}-widget-editing`; // Create an editing section attribute

export const getEditingSectionAttr = () => `data-${globalConfig.productNameSpace}-widget-editing-section`; // Create an editing area attribute

export const getEditingAreaAttr = () => `data-${globalConfig.productNameSpace}-widget-editing-area`; // Create an editing area meta data attribute

export const getEditingAreaMetaDataAttr = () => `data-${globalConfig.productNameSpace}-widget-editing-area-meta-data`;
export default useEditingAreas;