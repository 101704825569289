import { marked } from 'marked';
import { useMemo } from 'react';
import templateEngine from '@/utils/templateEngine';
import { selectionResultsToHash, seriesOptionsToHash } from '@/utils/widget/hashing';
export const DEFAULT_COLORS = ['#ffbdc0', '#fb7a80', '#fa4d55', '#cd6c70', '#f81721', '#c6050e', '#e70610', '#840309', '#630207', '#000'];
export default function useStackedColumnsData(config) {
  const {
    results,
    seriesOptions,
    selectionsStrategy = 'default',
    colorPallet = DEFAULT_COLORS
  } = config;
  const hasRenderableResults = results && results.length >= 1 && results.some(result => result.every(report => report && report.data && Boolean(report.data.length)));
  const resultsHash = selectionResultsToHash(results);
  const optionsHash = seriesOptionsToHash(seriesOptions);
  const colorPalletHash = colorPallet.join(',');
  const {
    data,
    columnCount
  } = useMemo(() => {
    if (!hasRenderableResults) {
      return {
        data: [],
        columnCount: 0
      };
    } // Each columns values are
    // grouped together with other
    // columns and assembed into a
    // singular column by Highcharts


    const categories = {};
    const colorsLen = colorPallet.length;
    const categorizer = selectionsStrategy === 'default' ? categorizeByReportsAndCollectionsFactory(categories) : categorizeBySelectionsFactory(categories); // Flatten groups together individual columns

    results.forEach(collection => {
      // Add each collection's selections to
      // their respective groups
      collection.map(group => {
        const {
          selectionIndex,
          selections
        } = group;
        const options = seriesOptions[selectionIndex] || {
          selections
        }; // empty options

        return {
          group,
          options
        };
      }) // Add each piece of data to its'
      // respective category/group
      .forEach((_ref, index) => {
        let {
          group,
          options = {}
        } = _ref;
        return categorizer({
          group,
          options,
          index
        });
      });
    }); // Sort and colorize categories
    // into final data used

    const data = Object.values(categories) // @ts-ignore
    .sort((a, b) => a.sum - b.sum) // sort ascending
    .map((_ref2, i) => {
      let {
        name,
        data,
        templates,
        selections,
        selectionIndexes
      } = _ref2;
      const color = colorPallet[i % colorsLen]; // Add color

      const isNameArr = Array.isArray(name);
      return {
        name,
        data: data.filter(y => typeof y === 'number').map((y, k) => ({
          y,
          color,
          template: templates[k] || '',
          selection: selections[k],
          selectionIndex: selectionIndexes[k],
          name: isNameArr ? name[k] : name
        }))
      };
    }); // Lookup column count via category
    // containing the most segments, as
    // each segment belongs to a unique column

    const columnCount = data.reduce((acc, seriesItem) => {
      // @ts-ignore
      const {
        data: categoryData = []
      } = seriesItem;
      const dataLength = categoryData.length;

      if (dataLength > acc) {
        acc = dataLength;
      }

      return acc;
    }, 0);
    return {
      data,
      columnCount
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRenderableResults, resultsHash, optionsHash, selectionsStrategy, colorPalletHash]);
  return {
    data,
    columnCount
  };
}

// Categorize a by converting each
// collection/report into a column
function categorizeByReportsAndCollectionsFactory(categories) {
  return config => {
    const {
      group,
      options,
      index
    } = config; // Provide default template

    const template = options.template || `{{value}}`;
    const customLabels = options.labels || {};
    group.data.forEach(item => {
      const value = item.value; // Setup non-existent category

      if (!categories[index]) {
        categories[index] = {
          name: customLabels[item.labelCell || ''] || options.label || item.label || group.selections,
          sum: 0,
          data: [],
          selections: [],
          selectionIndexes: [],
          templates: []
        };
      }

      categories[index].data.push(value);
      categories[index].templates.push(marked.parseInline(templateEngine(template, {
        value
      })));
      categories[index].sum += value; // Add the selection responsible
      // for this this item in the dataset

      categories[index].selections.push(group.selections); // Add the selection index responsible
      // for this this item in the dataset

      categories[index].selectionIndexes.push(group.selectionIndex);
    });
  };
} // Categorize a by converting a
// single selection into a column


function categorizeBySelectionsFactory(categories) {
  const selectionGroupCounter = {};
  return config => {
    const {
      group,
      options
    } = config; // Provide default template

    const template = options.template || `{{value}}`;
    const customLabels = options.labels || {}; // Increment selection category counter

    const selectionIndex = group.selectionIndex;
    selectionGroupCounter[selectionIndex] = selectionGroupCounter[selectionIndex] + 1 || 0; // Index of item within selection

    const index = selectionGroupCounter[selectionIndex];
    const selectionItem = group.data[0] || {
      value: 0
    }; // Only single data

    categories[index] = categories[index] || {
      name: [],
      sum: 0,
      data: [],
      selections: [],
      selectionIndexes: [],
      templates: []
    }; // Add name

    categories[index].name = padArrayLeft(categories[index].name, selectionIndex, 0);
    categories[index].name.push(customLabels[selectionItem.labelCell || ''] || options.label || selectionItem.label || group.selections); // Add data

    categories[index].data = padArrayLeft(categories[index].data, selectionIndex, 0);
    categories[index].data.push(selectionItem.value); // Add template

    categories[index].templates = padArrayLeft(categories[index].templates, selectionIndex, '');
    categories[index].templates.push(marked.parseInline(templateEngine(template, {
      value: selectionItem.value
    }))); // Add the selection responsible
    // for this this item in the dataset

    categories[index].selections.push(group.selections); // Add the selection index responsible
    // for this this item in the dataset

    categories[index].selectionIndexes.push(selectionIndex); // Update category sum

    categories[index].sum += selectionItem.value;
  };
} // Pad the left of an array, with
// any value, until an index


function padArrayLeft(arr, end, value) {
  if (arr.length >= end) {
    return arr;
  }

  const result = [...Array(end - arr.length).fill(value)];
  result.push(...arr);
  return result;
}